class BlockAnimation {
    constructor() {
        this.blocks = Array.from(document.querySelectorAll('.container-blocks > *:not(.container-block__gallery-grid)'));
        this.images = Array.from(document.querySelectorAll('img:not(.dont-animate)'));
        this.other = Array.from(document.querySelectorAll('.animate-appear'));
        this.elementsToAnimate = [...this.blocks, ...this.images, ...this.other];
    }
    initAnimations() {
        var _a;
        (_a = this.blocks[0]) === null || _a === void 0 ? void 0 : _a.classList.add('dont-animate');
        this.elementsToAnimate.map(el => el.classList.add('will-animate'));
        this.setVisibilityAll();
        document.addEventListener('scroll', this.setVisibilityAll.bind(this));
    }
    setVisibilityAll() {
        this.blocks.map(element => this.setVisibility(element, 0.18));
        this.images.map(element => this.setVisibility(element, 0));
        this.other.map(element => this.setVisibility(element, 0));
    }
    setVisibility(element, offsetK) {
        if (!element) {
            return;
        }
        if (this.partialInView(element, offsetK)) {
            if (!element.classList.contains('in-view')) {
                element.classList.add('in-view');
            }
        }
        else {
            if (element.classList.contains('in-view')) {
                element.classList.remove('in-view');
            }
        }
    }
    partialInView(element, offsetK) {
        const { top } = element.getBoundingClientRect();
        const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
        const offset = viewportHeight * offsetK;
        return top <= viewportHeight - offset;
    }
}